import Typography from "@mui/material/Typography";
import LogoSm from "assets/images/logo-sm.jpeg";
import PrintShoppeLogo from "assets/images/print_shoppe_logo.png";
import { useSelector } from "react-redux";
import { StyledSmLogo, StyledTitleWrapper } from "styles/components/header";

const Title = () => {
  const user = useSelector((state) => state?.user);

  return (
    <StyledTitleWrapper>
      {user?.vendorName === "The_Print_Shoppe" ? (
        <StyledSmLogo
          style={{ background: "#fff", height: "50px", width: "100px" }}
          alt="logo"
          src={PrintShoppeLogo}
        />
      ) : (
        <StyledSmLogo alt="logo" src={LogoSm} />
      )}
      <Typography variant="h6">TruTrac (Customer)</Typography>
    </StyledTitleWrapper>
  );
};

export default Title;
