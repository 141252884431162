import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import PageTitle from "components/PageTitle";
import Tabs from "components/Tabs";
import OrderCard from "components/OrderCard";
import TagsAutocomplete from "components/TagsAutocomplete";
import Autocomplete from "components/Autocomplete";
import Pagination from "components/Pagination";
import SearchTextFieldInput from "components/SearchTextFieldInput";
import { TABS_TITLES, QUICK_FILTER_OPTIONS, defined } from "pages/orders/data";
import { usePost } from "hooks/usePost";
import { CONFLUENCE_ORDERS, DECORATIONS } from "constants/services";
import { ORDER } from "constants/routes";
import {
  StyledContainer,
  NoDataFound,
  Filter,
  OrderDetails,
  QuickFilters,
} from "styles/pages/orders";

const take = 8;

const Orders = () => {
  const navigate = useNavigate();
  const { mutateAsync } = usePost();
  const [state, setState] = useState({ selectedTab: 0, page: 1 });
  const user = useSelector((s) => s?.user);

  const fetchOrders = async () => {
    const { data: orders } = await mutateAsync({
      url: CONFLUENCE_ORDERS,
      data: {
        take,
        skip: (state.page - 1) * take,
        status: state?.searchOrder
          ? null
          : TABS_TITLES[state?.selectedTab]?.value,
        search: state?.searchOrder || null,
        decoration: state?.searchOrder
          ? null
          : state?.decoration?.value || null,
        artProofStatus: state?.quickFilter ? state?.quickFilter?.id : null,
        vendorName: user?.vendorName,
      },
    });
    setState({
      ...state,
      orders,
      decoration: state?.searchOrder ? null : state?.decoration,
    });
  };

  useEffect(() => {
    fetchOrders();
  }, [state?.page, state?.selectedTab, state?.decoration, state?.quickFilter]);

  return (
    <StyledContainer fixed>
      <PageTitle
        title={
          user?.vendorName ? "The Print Shoppe TruTrac App" : "BOT TruTrac App"
        }
      />

      <Filter>
        <SearchTextFieldInput
          label="Search Order"
          value={state?.searchOrder}
          handleChange={(v) => setState({ ...state, searchOrder: v })}
          handleSubmit={() => {
            if (state?.page === 1) {
              fetchOrders();
            } else {
              setState({ ...state, page: 1 });
            }
          }}
        />

        <TagsAutocomplete
          multiple={false}
          optionApiKey="decoCode"
          apiEndPoint={DECORATIONS}
          value={state?.decoration || { title: "", value: "" }}
          setValue={(decoration) => {
            if (state?.searchOrder) {
              setState({ ...state, decoration, searchOrder: "" });
            } else {
              setState({ ...state, decoration });
            }
          }}
          label="Decorations"
        />
      </Filter>

      <Tabs
        disabled={!!state?.searchOrder}
        tabs={TABS_TITLES?.map((t) => t?.title)}
        onTabChange={(v) => setState({ ...state, selectedTab: v, page: 1 })}
      >
        <OrderDetails>
          {TABS_TITLES[state?.selectedTab]?.value === defined?.value ? (
            <QuickFilters>
              <Autocomplete
                label="Quick Filter"
                options={QUICK_FILTER_OPTIONS}
                value={state?.quickFilter}
                setValue={(v) => setState({ ...state, quickFilter: v })}
                inputValue={state?.quickFilterInputValue}
                setInputValue={(v) =>
                  setState({ ...state, quickFilterInputValue: v })
                }
              />
            </QuickFilters>
          ) : (
            <QuickFilters />
          )}

          {state?.orders?.data?.length > 0 && (
            <Pagination
              count={Math.ceil(state.orders.totalCount / take)}
              page={state?.page}
              handleChange={(e, v) => setState({ ...state, page: v })}
            />
          )}
        </OrderDetails>

        <Grid container spacing={3} sx={{ marginTop: "1px" }}>
          {state?.orders?.data?.map((o) => (
            <Grid item xs={3} key={`${o?.orderNumber}_${o?.vendorName}`}>
              <OrderCard
                data={o}
                refresh={fetchOrders}
                handleClick={() =>
                  navigate(`${ORDER}/${o?.orderNumber}/${o?.vendorName}`)
                }
              />
            </Grid>
          ))}
        </Grid>

        {!state?.orders?.data?.length && (
          <NoDataFound>No data found !</NoDataFound>
        )}
      </Tabs>
    </StyledContainer>
  );
};

export default Orders;
